import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { Backdrop, Grid, Typography, IconButton } from "@mui/material/";
import { useReactPWAInstall } from "react-pwa-install";
import LinkUserWithOrg from "./LinkUserWithOrg";
import UseGetUserInfo from "../hooks/useGetUserInfo";

import { getSessionValue } from "../services/organizations/SessionManager";

import { ReactComponent as MyMenuIcon } from "../media/icons/menu.svg";
import LogoCrecerLab from "../media/icons/crecerlab_logo.png";
import HelpModal from "./helpModal";
import FeedBack from "./AnswerFeedback";
import useStyles from "./navMenuStyles";
import {
  Avatar,
  Install,
  LinkWithOrg,
  Logout,
  Questions,
  Share,
  Updates,
  ChevronLeft,
  Subscriptions,
  Like,
} from "./NavBarIcons";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";

export default function NavMenu({
  logout,
  share,
  selectPage,
  token,
  hasLogin,
}) {
  const classes = useStyles();
  const [displayLink, setDisplayLink] = useState(false);
  const [displayLinkTutorial, setDisplayLinkTutorial] = useState(false);
  const [displayFeedback, setDisplayFeedback] = useState(false);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const { getUserInfo, user_info, success, isLoading } = UseGetUserInfo();

  function getTFromNavMenu() {
    const tokenNameList = ["development", "staging", "production"];
    tokenNameList.forEach((e) => {
      let token = localStorage.getItem(`token_${e}`);
      if (token) {
        getUserInfo(token);
      }
    });
  }

  useEffect(() => {
    if (!success && !isLoading && hasLogin) {
      getTFromNavMenu();
    }
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { pwaInstall } = useReactPWAInstall();

  const handleInstallClick = () => {
    pwaInstall({
      title: "Instalar Lisa",
      features: (
        <Typography variant="contained">
          <h5>Características clave</h5>
          <ul>
            <li>Accede de forma rápida</li>
            <li>Recibe notificaciones de tus procesos en curso</li>
            <li>Potencia tu rendimiento con información verificada</li>
          </ul>
        </Typography>
      ),
      description: (
        <Typography variant="contained">
          <h5>Descripción</h5>
          <p>
            Asistente virtual que te ayuda a potenciar tus capacidades, para
            cumplir tus objetivos.
          </p>
        </Typography>
      ),
    })
      .then(() => alert("Se ha instalado la app"))
      .catch(() => alert("Puedes instalar la app en otro momento"));
  };

  const navList = [
    {
      name: "Vincularme a una organización",
      action: () => setDisplayLink(true),
      icon: <LinkWithOrg />,
      order: 0,
      description:
        "Inicia sesión como empleado o cliente de una organización para obtener información personalizada de parte de Lisa.",
    },
    {
      name: "Organizaciones vinculadas",
      action: () => selectPage("organizations"),
      icon: <CorporateFareIcon />,
      order: 1,
      description:
        "Gestiona tus organizaciones vinculadas. Aquí puedes ver, agregar o eliminar las empresas con las que interactúas.",
    },
    {
      name: "Preguntas frecuentes",
      action: () => selectPage("faqs"),
      icon: <Questions />,
      order: 2,
      description:
        "Encuentra respuestas rápidas a las preguntas más comunes sobre Lisa y sus funcionalidades.",
    },
    {
      name: "Tutoriales",
      action: () => setDisplayLinkTutorial(true),
      icon: <Subscriptions />,
      order: 3,
      description:
        "Conoce las diferentes funcionalidades de Lisa con nuestra guía de videos demostrativos.",
    },
    {
      name: "Actualizaciones",
      action: () => selectPage("updates"),
      icon: <Updates />,
      order: 4,
      description:
        "Mantente al día con las últimas mejoras y novedades de Lisa para aprovechar al máximo su potencial.",
    },
    {
      name: "Compartir",
      action: (e) => share(e.target),
      icon: <Share />,
      order: 5,
      description:
        "Recomienda Lisa a tus contactos y ayúdalos a descubrir la inteligencia artificial que simplifica sus tareas.",
    },
    {
      name: "Instalar en el escritorio",
      action: handleInstallClick,
      icon: <Install />,
      order: 6,
      description:
        "Accede a Lisa de forma rápida y sencilla desde tu escritorio.",
    },
    {
      name: "Enviar comentarios",
      action: () => setDisplayFeedback(true),
      icon: <Like />,
      order: 7,
      description:
        "Cuéntanos tus opiniones y valoraciones para mejorar la experiencia con Lisa.",
    },
    {
      name: "Cerrar sesión",
      action: logout,
      icon: <Logout />,
      order: 8,
      description:
        "Finaliza tu sesión de forma segura y protege tu información personal.",
    },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    )
      return;

    setState((prevState) => ({ ...prevState, [anchor]: open }));
  };

  const list = (anchor) => (
    <Box
      className={classes.container}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 349,
        paddingLeft: "37px",
        paddingTop: "61px",
        paddingRight: "38px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div style={{ flex: 1 }}>
        <div style={{ marginBottom: "57px", position: "relative" }}>
          <Grid container alignItems="center" className="nav-header">
            <Grid item sx={{ marginRight: "16px", position: "relative" }}>
              <Avatar />
            </Grid>
            <Grid item>
              <h4 className="account-title">{user_info?.name}</h4>
              <p className="account-link" onClick={() => selectPage("account")}>
                Ver detalles de la cuenta
              </p>
            </Grid>
          </Grid>
        </div>
        <List className={classes.listRoot}>
          {navList.map((item, index) => (
            <ListItem
              key={item.name}
              disablePadding
              sx={{
                mb: index !== navList.length - 1 ? "32px" : "0",
                ...(index === navList.length - 1 && { mb: "10vh" }),
              }}
              onMouseEnter={() => {
                setHoveredIndex(index);
                window.scrollBy({
                  top: 50,
                  behavior: "smooth",
                });
              }}
              onMouseLeave={() => setHoveredIndex(null)}
              style={{ cursor: "pointer", alignItems: "flex-start" }}
            >
              <div
                onClick={item.action}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "35px",
                    marginTop: "7px",
                    marginRight: "2px",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontFamily: "Figtree-Regular",
                    color: "#fff",
                    "& .MuiListItemText-primary": {
                      fontSize: "1.125rem",
                      mb: "0.25rem",
                      fontWeight: "bold",
                    },
                    "& .MuiListItemText-secondary": {
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.7)",
                      display:
                        isMobile || hoveredIndex === index ? "block" : "none",
                    },
                  }}
                  primary={item.name}
                  secondary={item.description}
                />
              </div>
            </ListItem>
          ))}
        </List>
      </div>

      <div className={classes.logo}>
        <img
          src={LogoCrecerLab}
          alt="Logo CrecerLab"
          style={{ marginLeft: -7 }}
        />
        <Typography
          sx={{ fontFamily: "Figtree-Regular", color: "#fff", mt: 1 }}
        >
          Versión: 2.1.
        </Typography>
      </div>
      <IconButton
        onClick={toggleDrawer(anchor, false)}
        style={{
          position: "absolute",
          right: -25,
          bottom: 30,
          backgroundImage:
            "linear-gradient(142deg, #8066ec -44%, #d5a9ff 40%, #59d1ff 125%)",
          padding: 12,
          border: "solid 2px #8066ec",
        }}
      >
        <ChevronLeft />
      </IconButton>
    </Box>
  );

  return (
    <div style={{ position: "fixed", zIndex: 1001 }}>
      <MyMenuIcon
        onClick={toggleDrawer("left", true)}
        className="nav-menu"
        style={{
          width: "50px",
          height: "50px",
          background: "rgba(0, 0, 0, 0.28)",
          color: "white",
          borderRadius: "50%",
          margin: "10px 10px",
          padding: "5px",
        }}
      />

      <SwipeableDrawer
        className="main-navigation-container"
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
        style={{ zIndex: 30000 }}
        sx={{
          "& .MuiPaper-root": {
            overflow: "visible",
          },
        }}
      >
        {list("left")}
      </SwipeableDrawer>

      {displayLink && (
        <Backdrop open={displayLink} className="link-org">
          <LinkUserWithOrg setDisplay={setDisplayLink} token={token} />
        </Backdrop>
      )}

      {displayLinkTutorial && (
        <Backdrop open={displayLinkTutorial}>
          <HelpModal
            isVisible={displayLinkTutorial}
            onClose={() => setDisplayLinkTutorial(false)}
          />
        </Backdrop>
      )}

      {displayFeedback && (
        <Backdrop open={displayFeedback}>
          <FeedBack token={token} onClose={() => setDisplayFeedback(false)} />{" "}
        </Backdrop>
      )}
    </div>
  );
}
