import React, { useEffect, useState } from "react"
import { TextField, Fab, FormControlLabel } from "@mui/material"
import { FormGroup, Switch } from "@mui/material"
import { Save } from "@mui/icons-material"
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import useGetUserInfo from "../hooks/useGetUserInfo"
import { Alert } from '@mui/material'
import '../css/accountSettings.css'

export function AccountSettings(auth_token) {
    const [name, setName] = useState('')
    const [cedula, setCedula] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [company, setCompany] = useState('')
    const [position, setPosition] = useState('')
    const [role, setRole] = useState('')
    const [email_notification, setEmailNotification] = useState(false)
    const [sms_notification, setSmsNotification] = useState(false)
    const [push_notification, setPushNotification] = useState(false)
    const [alert, setAlert] = useState()


    const {
        getUserInfo,
        user_info,
        updateUserInfo,
        error,
        success,
        isLoading
    } = useGetUserInfo()

    function handleSave() {

        if (!name || !phone) {
            displayAlert("Por favor completar todos los campos requeridos", "error");
            return;
        }

        let info = {
            name: name,
            last_name: lastName,
            email: email,
            cedula: cedula,
            phone: phone,
            company: company,
            position: position,
            role: null,
            email_notification: email_notification,
            sms_notification: sms_notification,
            push_notification: push_notification
        }
        updateUserInfo(auth_token.auth_token, info)
    }

    useEffect(() => {
        if (!user_info) {
            getUserInfo(auth_token.auth_token)
        } else {
            setName(user_info.name)
            setLastName(user_info.last_name)
            setCedula(user_info.cedula)
            setEmail(user_info.email)
            setPhone(user_info.phone)
            setCompany(user_info.company)
            setPosition(user_info.position)
            setRole(user_info.role?.[0])
            setEmailNotification(user_info.email_notification)
            setSmsNotification(user_info.sms_notification)
            setPushNotification(user_info.push_notification)
        }
    }, [user_info])

    const [user_name, setUserName] = useState('')
    const [user_lastname, setLastUserName] = useState('')


    return (
        <React.Fragment>
            <div className="account-settings">
                <div className="avatar">
                    <h4 className="title-name">Hola {user_name || user_info?.name} {user_lastname || user_info?.last_name}!</h4>
                    <p className="subtitle">Esta es tu información Personal</p>
                </div>
                <Stack direction="col" className="settings-form" spacing={2}>
                    <FormGroup>
                        <label>Nombre:</label>
                        <TextField
                            value={name}
                            autoComplete="off"
                            onChange={(e) => {
                                const input = e.currentTarget.value;
                                const filteredInput = input.replace(/[^a-zA-Z\s]/g, '');
                                setName(filteredInput);
                                setUserName(filteredInput);

                            }}
                        />
                        <label>Apellido:</label>
                        <TextField
                            value={lastName}
                            autoComplete="off"
                            onChange={(e) => {
                                const input = e.currentTarget.value;
                                const filteredInput = input.replace(/[^a-zA-Z\s]/g, '');
                                setLastUserName(filteredInput);
                                setLastName(filteredInput);
                            }}
                        />
                        <label>Cédula:</label>
                        <TextField
                            value={cedula}
                            autoComplete="off"
                            disabled={true}
                            onChange={(e) => {
                                const input = e.currentTarget.value;
                                const filteredInput = input.replace(/[^\d-]/g, '');
                                const match = filteredInput.match(/^(\d{3})-?(\d{7})-?(\d{1})$/);
                                setCedula(filteredInput)
                                // if (match) {
                                //     const formattedInput = match[1] + '-' + match[2] + '-' + match[3];
                                //     setCedula(formattedInput);
                                // }
                            }}
                        />

                        <label>Email:</label>
                        <TextField value={email} autoComplete="off" disabled={true} onChange={(e) => setEmail(e.currentTarget.value)} />
                        <label>Teléfono:</label>
                        <TextField value={phone} autoComplete="off" onChange={(e) => setPhone(e.currentTarget.value)} disabled={true} />
                        {/* <label>Empresa:</label>
                        <TextField value={company} autoComplete="off" onChange={(e) => setCompany(e.currentTarget.value)} />
                        <label>Posición:</label>
                        <TextField value={position} autoComplete="off" onChange={(e) => setPosition(e.currentTarget.value)} />
                        <label>Actualmente soy:</label>
                        <TextField value={user_info?.role} autoComplete="off" disabled /> */}
                    </FormGroup>

                    <label className="label-notification">Notificaciones</label>
                    <FormGroup>
                        <FormControlLabel
                            labelPlacement="start"
                            control={<Switch
                                checked={email_notification}
                                onChange={(e) => setEmailNotification(e.currentTarget.checked)} />}
                            label="Notificaciones de Email" />
                        <FormControlLabel
                            labelPlacement="start"
                            control={<Switch checked={sms_notification}
                                onChange={(e) => setSmsNotification(e.currentTarget.checked)} />}
                            label="Notificaciones de SMS" />
                        <FormControlLabel
                            labelPlacement="start"
                            control={<Switch
                                checked={push_notification}
                                onChange={(e) => setPushNotification(e.currentTarget.checked)} />}
                            label="Notificaciones Push" />
                    </FormGroup>
                </Stack>
                {alert}
                <Fab variant="extended" color="primary" aria-label="add" onClick={() => handleSave()} className="save-button">
                    Guardar Cambios
                </Fab>
            </div>
        </React.Fragment>
    )


    function displayAlert(message, type) {
        if (type === 'error') {

            setAlert(<Alert className='alert' severity="error">{message}</Alert>);
            setTimeout(() => {
                setAlert()
            }, 3000)

        } else {

            setAlert(<Alert className='alert' severity="success">{message}</Alert>);
            setTimeout(() => {
                setAlert()
            }, 3000)

        }

    }

}

function stringAvatar(name) {

    let strings = name.split(" ")

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${strings[0][0]}${strings[1][0]}`,
    };
}


function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}
